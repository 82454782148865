.fab {
    margin: 0px;
    top: auto;
    right: 20px;
    bottom: 80px;
    left: auto;
    position: fixed;
};

.barItems { 
    display: flex;
    width: '10px'
}

.logo { 
    max-width: 30px;
    min-width: 30px;
    max-width: 60px;
    max-height: 60px;
}

.searchAndLogin {
    display: flex;
  }