a.hash:link {color: rgb(193, 151, 210); text-decoration: none; font-weight: bold;}


a.hash:visited {color: rgb(193, 151, 210); text-decoration: none; font-weight: bold; }


a.hash:hover {color: rgb(193, 151, 210); text-decoration: none; font-weight: bold;}


a.hash:active {color: rgb(193, 151, 210); text-decoration: none; font-weight: bold;}


.hashat-button {
   font-weight: bold;
}


